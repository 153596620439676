@use "./variables.scss" as *;

/* Card */

.card {
  display: flex;
  max-width: 929px;
  position: relative;

  .card-left {
    position: relative;
    border: none;
    padding: 0;
    cursor: pointer;
    @include flex-center;

    &-img {
      max-width: 350px;
      @include whiteborder;
      border-bottom: 80px $whitecolor solid;
      aspect-ratio: 365/487;

      @include phone-only {
        max-width: 100%;
      }
    }

    &-title {
      font-family: $secondaryfont;
      letter-spacing: 5px;
      position: absolute;
      bottom: 25px;
      font-size: 1.5em;
      text-transform: capitalize;
    }
  }

  &.card-open .card-right {
    @include flex-center;
    flex-direction: column;
    position: absolute;
    background: $whitecolor;
    padding: 0px 36px;
    height: 100%;
    gap: 20px;
  }

  .card-right {
    display: none;
    padding-left: 20px;
    line-height: 2.0;
    width: 100%;
    border-radius: 4px;
    max-width: 100%;

    &-location, &-dates {
      @include flex-center;
      gap: 10px;
    }

    &-dates {
      text-transform: uppercase;
      font-size: 12px;
    }

    &-location-name {
      text-transform: uppercase;
      font-size: 14px;
    }

    &-description {
      text-align: center;
      font-size: 14px;
    }

    &-google-data i, &-google-data a {
      color: $accentcolor;
    }

    &-google-data a {
      font-size: 15px;
      display: flex;
      align-items: center;
      gap: 5px;

      &:hover {
        text-decoration: underline;
        text-underline-offset: 5px;
      }
    }

    &-title {
      font-size: 25px;
      text-transform: capitalize;
      font-family: 'Indie Flower', sans-serif;
      font-size: 1.75em;
    }

    &-tags  {
      text-align: center;
    }

    &-tags span {
      background: $greycolor;
      border-bottom-right-radius: 2px;
      border-top-right-radius: 2px;
      text-transform: uppercase;
      font-size: .7rem;
      height: 28px;
      letter-spacing: 1px;
      line-height: 28px;
      margin-bottom: 10px;
      padding: 0 10px 0 10px;
      position: relative;
      display: flex;
      align-items: center;
      gap: 8px;

      &:before {
        border-color: transparent $greycolor transparent transparent;
        border-style: solid;
        border-width: 14px 14px 14px 0;
        content: "";
        height: 0;
        left: -13.5px;
        position: absolute;
        top: 0;
        width: 0;
      }
    }

    &-social-icons-grid-row a {
      font-size: 100px;
      margin: auto 30px;
      color: $accentcolor;
      cursor: pointer;

      @include phone-only {
        font-size: 60px;
      }

      &:hover {
        opacity: 75%;
      }
    }
  }
}

.hero .card {
  @include phone-only {
    max-width: 90%;
  }

  .card-left-img {
    max-width: 400px;
    height: 100%;
    width: 100%;
  }
}
